import React from 'react';
import { Link } from 'react-router-dom';

function FaqElem(props) {

  function toggleCollapse(id, id_btn) {
  
    const elem = document.getElementById(id);
    const btn = document.getElementById(id_btn);
  
    btn.classList.toggle("active_faq");
    // var content = this.nextElementSibling;
    if (elem.style.display === "block") {
      elem.style.display = "none";
    } else {
      elem.style.display = "block";
    }

  }

  return (
    <li data-aos="fade-up" data-aos-delay={props.faq && props.faq.id*100}>
        <i className="bx bx-help-circle icon-help"></i> 
        <Link id={`collapsible-${props.faq.id}`} data-bs-toggle="collapse" className="collapsible" data-bs-target="#faq-list-1" onClick={() => toggleCollapse(`faq-list-${props.faq.id}`, `collapsible-${props.faq.id}`)}>
            {props.faq && props.faq.question} <i className="bx bx-chevron-down icon-show"></i>
            <i className="bx bx-chevron-up icon-close"></i>
        </Link>
        <div id={`faq-list-${props.faq.id}`} className="content" data-bs-parent=".faq-list">
            <p className='faq_desc'>
            {props.faq && props.faq.reponse}
            </p>
        </div>
    </li>
  )
}

export default FaqElem