import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// const firebaseConfig = {
//       apiKey: process.env.NOVA_FIREBASE_KEY,
//       // authDomain: process.env.NOVA_FIREBASE_DOMAIN,
//       // databaseURL: process.env.NOVA_FIREBASE_DATABASE,
//       projectId: process.env.NOVA_FIREBASE_PROJECT_ID,
//       storageBucket: process.env.NOVA_FIREBASE_STORAGE_BUCKET,
//       // messagingSenderId: process.env.NOVA_FIREBASE_SENDER_ID,
//       appId: process.env.NOVA_MESSAGING_APP_ID,
// };

const firebaseConfig = {
      apiKey: "AIzaSyB02JTxeMy__8JqdHrC-inQ_mWI6CYhM2U",
      projectId: "nova-b6a7f",
      storageBucket: "europe-west",
      appId: "1:935063982637:android:6ce744c42f28c4a1e49ea4"
    };

const firebaseConf = initializeApp(firebaseConfig);
const db = getFirestore(firebaseConf);

export {db};
