import React from 'react';
import polygon2 from '../../assets/polygon-2.svg';
import iconNovaBlanc from '../../assets/logo_novazon_icone_blanc.svg';


function HomeBodySection0() {
  return (
    <div className='section0_home'>
        <div className='left_part_section0_home'>
            <div className='section0_home_title'>
                De l’envoi à la réception
                <br />
                en toute quiétude
            </div>
            <div className='section0_home_description'>
                Sur votre plateforme de livraison dédiée, nous simplifions votre expérience <br />
                en vous offrant des solutions de suivi de colis efficaces et des livraisons <br />
                rapides via notre réseau de points relais.
            </div>

            <img
            className="LOGO-NOVAZON-icone_section0_home"
            src={iconNovaBlanc}
            alt="Logo NOVAZON icone"
        />
        <img
                className="delivery-address_section0_displaynone d_none"
                alt="Delivery address"
                src="delivery-address-rafiki.png"
            />

        </div>
        <div className='right_part_section0_home'>
            <img
                className="delivery-address_section0"
                alt="Delivery address"
                src="delivery-address-rafiki.png"
            />
        </div>
       
    </div>
  )
}

export default HomeBodySection0