import React, {useEffect} from 'react';
import './faq.css';
import { Link } from 'react-router-dom';
import FaqElem from "../../components/body/FaqElem";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';


function Faq() {

    useEffect(() => {
        document.title = 'FAQs | NOVAZON RELAY';
        window.scrollTo(0, 0);
      }, []);

    const faqs = [
        {
            "id": 1,
            "question": "Qu'est-ce que Novazonrelay?",
            "reponse": "Novazonrelay est un réseau de points relais qui propose des services de livraison pour vos colis. Notre plateforme vous permet de suivre en temps réel l'emplacement de vos colis livrés en points relais.",
        },
        {
            "id": 2,
            "question": "Comment puis-je suivre mon colis avec Novazonrelay?",
            "reponse": "Connectez-vous à la section 'Suivi de Colis' en utilisant le numéro de suivi unique de votre colis. Vous pouvez également suivre une livraison groupée en entrant le numéro de suivi associé à la livraison groupée.",
        },
        {
            "id": 3,
            "question": "Comment suivre une livraison groupée en tant que vendeur?",
            "reponse": "Les vendeurs peuvent utiliser la fonction 'Suivre une Livraison Groupée' en entrant le numéro de suivi spécifique associé à leur livraison groupée. Cela permet un suivi simplifié pour les expéditions impliquant plusieurs colis.",
        },
        {
            "id": 4,
            "question": "Quels types de colis puis-je faire livrer avec Novazonrelay?",
            "reponse": "Novazonrelay accepte une large gamme de colis, des petites enveloppes aux colis plus volumineux. Assurez-vous de respecter les restrictions de taille et de poids spécifiées lors de la commande.",
        },
        {
            "id": 5,
            "question": "Combien de temps faut-il pour qu'un colis soit livré en point relais?",
            "reponse": "Le délai de livraison dépend de divers facteurs tels que la distance entre l'expéditeur et le point relais, le mode de livraison choisi, ainsi que d'autres facteurs. Généralement, les colis sont livrés dans un délai de 3 à 10 jours ouvrables.",
        },
        {
            "id": 6,
            "question": "Puis-je modifier l'adresse de livraison après avoir passé ma commande?",
            "reponse": "Malheureusement, une fois la commande passée, l'adresse de livraison ne peut généralement pas être modifiée. Assurez-vous de vérifier soigneusement les informations avant de finaliser votre commande.",
        },
        {
            "id": 7,
            "question": "Que faire si mon colis est en retard ou si je rencontre un problème avec la livraison?",
            "reponse": "Si votre colis est en retard ou si vous rencontrez des problèmes avec la livraison, veuillez contacter notre service clientèle via hello.novazon@gmail.com ou contact@zurymarket.com. Nous ferons de notre mieux pour résoudre la situation rapidement.",
        },
        {
            "id": 8,
            "question": "Comment puis-je localiser le point relais le plus proche de chez moi?",
            "reponse": "Utilisez la fonction de recherche de points relais sur notre site en entrant votre emplacement. Vous verrez une liste des points relais disponibles à proximité, avec leurs adresses et horaires d'ouverture.",
        },
        {
            "id": 9,
            "question": "Y a-t-il des frais supplémentaires pour utiliser Novazonrelay?",
            "reponse": "Les frais de livraison dépendent du poids, de la taille, de la distance de livraison et du mode de livraison choisi. Vous pouvez consulter les frais de livraison avant de finaliser votre commande.",
        },
        {
            "id": 10,
            "question": "Mon colis est endommagé à la réception, que dois-je faire?",
            "reponse": "Si votre colis est endommagé à la réception, contactez notre service client via contact@zurymarket.com ou hello.novazon@gmail.com avec les photos des articles endommagés. Le remboursement est réservé uniquement aux utilisateurs de l'application mobile NOVAZON.",
        },
        {
            "id": 11,
            "question": "Comment puis-je donner des retours sur le service Novazonrelay?",
            "reponse": "Nous apprécions vos retours ! Vous pouvez nous faire part de vos commentaires en nous envoyant un e-mail à hello.novazon@gmail.com ou contact@zurymarket.com ou en utilisant notre formulaire de contact sur le site.",
        }
    ];
    

  return (
    <>
    <Header/>
    
    <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
            <h2>FAQ - Novazonrelay Suivi de Colis</h2>
            <p>Que vous cherchiez des informations supplémentaires, souhaitiez discuter de nos services, 
                n'hésitez pas à nous <Link to="/aide" style={{fontWeight:700 }} className='faq_contact_us'>contacter</Link>.</p>
            </div>

            <div className="faq-list">
            <ul>

                {faqs &&
                    faqs.map((faq, index) => (
                        <FaqElem faq={faq} key={index} />
                    ))}

            </ul>
            </div>

        </div>
    </section>
    <Footer/>
    </>
  )
}

export default Faq