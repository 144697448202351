import React, { useState } from 'react';
import HomeBodySection4 from './HomeBodySection4';
import HomeBodySection3 from './HomeBodySection3';
import HomeBodySection2 from './HomeBodySection2';
import HomeBodySection1 from './HomeBodySection1';
import HomeBodySection0 from './HomeBodySection0';
import TestFirebase from './TestFirebase';


function HomeBody() {
      return (
            <div className="home_element">
                  <div className="div">
                        {/* <TestFirebase /> */}
                        {/* Responsive section To keep after */}
                        <HomeBodySection0/>
                        <HomeBodySection1/>
                        <HomeBodySection2/>
                        <HomeBodySection3/>
                        <HomeBodySection4/>
                        {/* End responsive section to keep */}
                  </div>
            </div>
      );
}

export default HomeBody;
