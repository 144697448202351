import './style/global.css';
import './components/body/body.css';
import React from 'react';
import Home from './pages/Home/index';
import LivraisonGroup from './pages/LivraisonGroup/index';
import SuiviColis from './pages/SuiviColis/index';
import Relais from './pages/Relais/index';
import Error404 from './pages/Error404/index';
import Contact from './pages/Contact/index';
import Aide from './pages/Aide/index';
import Faq from './pages/Faq/index';
import Gallery from './pages/Gallery/index';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
      return (
            <BrowserRouter>
                  <Routes>
                        <Route exact path="/" element={<Home />}/>
                        <Route path='/livraison-group' element={<LivraisonGroup />}/>
                        <Route path='/suivi-colis' element={<SuiviColis />}/>
                        <Route path='/relais' element={<Relais />}/>
                        <Route path='/aide' element={<Aide />}/>
                        <Route path='/faqs' element={<Faq />}/>
                        <Route exact path='/contact-us' element={<Contact />}/>
                        <Route exact path='/gallery' element={<Gallery />}/>
                        <Route path="*" element={<Error404 />}/>   
                  </Routes>
            </BrowserRouter>
      );
}

export default App;
