import React, {useState} from 'react';

function ContactForm(props) {


  const [formState, setFormState] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);


  const submitHandler = (event) =>{
    event.preventDefault();
    const config = {
        Host : "smtp.elasticemail.com",
        Port : 2525,
        Username : "contact@zurymarket.com",
        Password : "7BF67BE8D5C9AB63D728228368595CD63960",
        To : 'contact@zurymarket.com',
        From : formState.email,
        Subject : "Contact depuis Novazon",
        Body : `${formState.name} : ${formState.description}`
    };

    if(window.Email){
        window.Email.send(config).then(() =>{
             console.log("Email sent");
             setIsFormSubmitted(true);

             setTimeout(() => {
              setIsFormSubmitted(false);
              setFormState({ name: '', email: '', description: '' });
            }, 5000);

            });
    }
}

  return (
    <div className='nova_contact_form'>
        <div className="container_contact">
          <div className="content_contact">
            <div className="left-side">
              
              <div className="email details">
                <i className="fas fa-envelope"></i>
                <div className="topic">Email</div>
                <div className="text-one">contact@zurymarket.com</div>
              </div>
              <div className="address details">
                <i className="fas fa-map-marker-alt"></i>
                <div className="topic">Adresse</div>
                <div className="text-two">Lomé, Togo.</div>
              </div>
              
            </div>
            <div className="right-side">
              <div className="topic-text">
                {props && props.page === 'contact' && "Contactez-nous"}
                {props && props.page === 'aide' && "Besoin d'aide !"}
              </div>
              <p className='contact_us_descr'> Que vous cherchiez des informations supplémentaires, souhaitiez discuter de nos services,
               n'hésitez pas à nous contacter. Votre satisfaction est notre priorité.</p>
            <form onSubmit={submitHandler}>
              <div className="input-box">
                <input type="text" placeholder="Nom" 
                name="name" 
                value={formState.name}
                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                required
                />
              </div>
              <div className="input-box">
                <input type="email" placeholder="Email" 
                name="email" 
                value={formState.email}
                onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                required
                />
              </div>
              <div className="input-box message-box">
              <input type="textarea" placeholder="Description..." 
              name="description" 
              value={formState.description}
              onChange={(e) => setFormState({ ...formState, description: e.target.value })}
              required
              />
              </div>
              <div className="button">
                <input type="submit" value="Envoyer" />
              </div>
            </form>
            {isFormSubmitted && (
            <div className="success-message">
              Votre message a été envoyé avec succès.
            </div>
          )}
          </div>
          </div>
        </div>
  </div>
  )
}

export default ContactForm