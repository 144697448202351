import React, {useState, useEffect} from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import mk_left from "../../assets/ellipse318.svg";
import mk_left_user from "../../assets/ellipse318user.svg";

function MapNova(props) {

  const [listRelais, setListRelais] = useState(props.listRelais);

  const CustomIcon = (number) => L.divIcon({ className: 'custom-icon', html: `<div class="custom-marker">
  <img src=${mk_left} alt="..." />
  <span>${number}</span>
  </div>` });

  const UserIcon = () => L.divIcon({ className: 'usercustom-icon', html: `<div class="usercustom-marker">
  <img src=${mk_left_user} alt="..." />
  <span>Vous</span>
  </div>` });


  useEffect(() => {
    setListRelais(props.listRelais);

}, [props.listRelais, props.userLocation]);

const MapComponent = () => {
  const map = useMap();

  const bounds = L.latLngBounds();

  if (props.userLocation) {
    bounds.extend(props.userLocation);
  }

  listRelais.forEach((relais) => {
    bounds.extend([relais.latitude, relais.longitude]);
  });

  map.fitBounds(bounds, { padding: [50, 50] });

  return null;
};

  return (
    <MapContainer center={props.userLocation || [6.2254148, 1.1946578]} zoom={12} scrollWheelZoom={true}>
      
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {props.userLocation && (
          <Marker position={props.userLocation} icon={UserIcon()}>
            <Popup>Vous êtes ici.</Popup>
          </Marker>
        )}

      {props.listRelais && props.listRelais.map((relais, index) => (
      <Marker position={[relais.latitude, relais.longitude]} key={index} icon={CustomIcon(relais.id_number)}>
        <Popup>
          {relais.relay_name}<br /> {relais.quartier} <br /> Tel: {relais.numero_telephone}<br/>
        </Popup>
      </Marker>
       ))}
     <MapComponent />

    </MapContainer>
  )
}

export default MapNova