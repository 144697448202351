import React, {useState, useEffect} from 'react';

function OrderDetail(props) {
  const [individualOrderNumber, setIndividualOrderNumber] = useState(props.commande.individualOrderNumber);
  const [name, setName] = useState(props.commande.firstName + " " + props.commande.lastName);
  const [quartier, setQuartier] = useState(props.commande.selectedQuartier);
  const [status, setStatus] = useState(props.commande.status);

  useEffect(() => {
    setIndividualOrderNumber(props.commande.individualOrderNumber);
    setName(props.commande.firstName + " " + props.commande.lastName);
    setQuartier(props.commande.selectedQuartier);
    setStatus(props.commande.status);

}, [props.commande])
 
  return (
    // <div className="order_form" key={props.id}>
    <div className={props.className} key={props.id}>
            <div className="nice-form-group">
            <label>N° de commande</label>
            <input type="text" placeholder="123456"  value={individualOrderNumber} readOnly/>
            </div>

            <div className="nice-form-group">
            <label>Nom du client</label>
            <input type="text" placeholder="Nom du client" value={name} readOnly />
            </div>

            <div className="nice-form-group">
            <label>Quartier</label>
            <input type="text" placeholder="Quartier" value={quartier} readOnly/>
            </div>

            <div className="nice-form-group">
            <label>Statut</label>
            <input type="text" placeholder="Statut" value={status} readOnly />
            </div>

    </div>
  )
}

export default OrderDetail
