import React, {useState, useEffect, useMemo} from 'react';

function RelaiListElem(props) {

  const [relais, setRelais] = useState(props.relais);
  const [index, setIndex] = useState(props.index);

  // Ouvert-Ferme code
  var [estOuvertMaintenant, setEstOuvertMaintenant] = useState(false);
  // const maintenant = new Date();
  // Wrap the initialization of 'maintenant' in useMemo
  const maintenant = useMemo(() => new Date(), []);

  // Get the day of the week (0-6), where Sunday is 0
  var dayOfWeek = maintenant.getDay();

  // Adjust the result so that Monday is 0
  var dayNumber = (dayOfWeek + 6) % 7;

  useEffect(() => {
      setRelais(props.relais); 
      setIndex(props.index); 

      var currentHourGMT = maintenant.getUTCHours();

      var currentMinuteGMT = maintenant.getUTCMinutes();

      if (relais.scheduling.length < dayNumber+1) {
        setEstOuvertMaintenant(false);
      }
      else{
        // Heures d'ouverture et de fermeture
        const heureOuverture = parseInt(relais.scheduling[dayNumber].start_time.HH);
        const heureFermeture = parseInt(relais.scheduling[dayNumber].end_time.HH);
        const minuteOuverture = parseInt(relais.scheduling[dayNumber].start_time.mm);
        const minuteFermeture = parseInt(relais.scheduling[dayNumber].end_time.mm);

        // Convertir les heures d'ouverture et de fermeture en minutes numériques
        const heureOuvertureMinutes = heureOuverture * 60 + minuteOuverture;
        const heureFermetureMinutes = heureFermeture * 60 + minuteFermeture;
        const heureActuelleMinutes = currentHourGMT * 60 + currentMinuteGMT;
       
        if (!isNaN(heureOuvertureMinutes) && !isNaN(heureFermetureMinutes)) {
          // Conversion was successful
          if(heureActuelleMinutes >= heureOuvertureMinutes && heureActuelleMinutes <= heureFermetureMinutes){
            setEstOuvertMaintenant(true);
          }
          else {
            setEstOuvertMaintenant(false);
          }
        }
          
      }
      // End Ouvert-Ferme code

    }, [props.relais, props.index,dayNumber,maintenant,relais.scheduling]);


  function openModalHoraires(id) {
      const modal = document.getElementById(id);
    if(modal && modal.style.display === "block"){
      modal.style.display = "none";
      }
    else {
      modal.style.display = "block";
    }

    }

  return (
  <>
  {relais &&
    <section className='relais_list_elem'>
     
            <div className="relais_list_elem_group">

              <div className='relais_inf_flex_row'>
                  <div className="relais_list_elem_overlap-group">
                      <div className="relais_list_elem_element">{index}</div>
                  </div>
                  <div className="relais_list_elem_name">{relais.relay_name}</div>
              </div>

              <div className='other_relais_info'>
                <div className="relais_list_elem_addr">{relais.quartier}</div>
                <div className="relais_list_elem_tel">Tel: {relais.numero_telephone}</div>
                <div className="relais_list_elem_ouvert">
                  <div className='open_close'>
                  { estOuvertMaintenant ? <h4 className="newbtn newbtn_open">ouvert</h4>
                  : 
                  <h4 className="newbtn newbtn_close">fermé</h4>}
                  </div>
                </div>
                <div className="relais_list_elem_horaire">
                 
                  <button className='horaires'  onClick={() => openModalHoraires(`modal_horaire_hidden_${index}`)}>Horaires
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{width: '15px',height: '15px'}}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                                        </svg>
                  </button>

                </div>
              </div>
               {/*  */}
               <section id={`modal_horaire_hidden_${index}`} className="modal_horaire hidden">
                    <div>
                    {relais.scheduling && relais.scheduling.length > 0 && 
                      <ul className='horaire_list'>

                        {relais.scheduling.length > 0 && 
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>  Lundi : </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[0].start_time.HH}h:{relais.scheduling[0].start_time.mm} - {relais.scheduling[0].end_time.HH}h:{relais.scheduling[0].end_time.mm}
                        </div>
                        </li>}

                        {relais.scheduling.length > 1 && 
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>Mardi :  </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[1].start_time.HH}h:{relais.scheduling[1].start_time.mm} - {relais.scheduling[1].end_time.HH}h:{relais.scheduling[1].end_time.mm}
                        </div>
                        </li>}

                        {relais.scheduling.length > 2 && 
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>Mercredi : </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[2].start_time.HH}h:{relais.scheduling[2].start_time.mm} - {relais.scheduling[2].end_time.HH}h:{relais.scheduling[2].end_time.mm}
                        </div>
                        </li>}

                        {relais.scheduling.length > 3 &&
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>Jeudi : </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[3].start_time.HH}h:{relais.scheduling[3].start_time.mm} - {relais.scheduling[3].end_time.HH}h:{relais.scheduling[3].end_time.mm}
                        </div>
                        </li> }

                        {relais.scheduling.length > 4 &&
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>Vendredi : </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[4].start_time.HH}h:{relais.scheduling[4].start_time.mm} - {relais.scheduling[4].end_time.HH}h:{relais.scheduling[4].end_time.mm}
                        </div>
                        </li> }

                        {relais.scheduling.length > 5 && 
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>Samedi : </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[5].start_time.HH}h:{relais.scheduling[5].start_time.mm} - {relais.scheduling[5].end_time.HH}h:{relais.scheduling[5].end_time.mm}
                        </div>
                        </li>}
                        {relais.scheduling.length > 6 && 
                        <li className='horaire_list_el'>
                        <div className='part1_horaire'>Dimanche : </div>
                        <div className='part2_horaire'> 
                        {relais.scheduling[6].start_time.HH}h:{relais.scheduling[6].start_time.mm} - {relais.scheduling[6].end_time.HH}h:{relais.scheduling[6].end_time.mm}
                        </div>
                        </li>}

                      </ul>
                      }
                    </div>
                  </section>
                  {/*  */}
              
            </div>

    </section> }
    </>
  )
}

export default RelaiListElem