import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import OrderDetail from '../../components/body/OrderDetail';
import './livraisongroup.css';
import vector from '../../assets/vector.svg';
import Footer from '../../components/footer/Footer';
import { useLocation } from 'react-router-dom';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Loading from '../../components/body/Loading';
import EmptyResultPage from '../../components/body/EmptyResultPage';
import arrow33 from '../../assets/arrow-33.svg';

function LivraisonGroup() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const numeroCommandeGroup = queryParams.get('query');
  const [commandeGroup, setCommandeGroup] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    if(commandeGroup && commandeGroup.length > 0){
    const newIndex = (currentIndex + 1) % commandeGroup[0].orders.length;
    setCurrentIndex(newIndex);}
  };

  const goToPrevSlide = () => {
    if(commandeGroup && commandeGroup.length > 0){
    const newIndex = (currentIndex - 1 + commandeGroup[0].orders.length) % commandeGroup[0].orders.length;
    setCurrentIndex(newIndex);}
  };

  useEffect(() => {

    document.title = 'LIVRAISON GROUPEE | NOVAZON RELAY';
    window.scrollTo(0, 0);

    const fetchGroupCommande = async () => {
      try {

        const q = query(collection(db, 'Livraisons_Externes'), where('deliveryNumber', '==', numeroCommandeGroup));
        const querySnapshot = await getDocs(q);
  
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
  
        setCommandeGroup(data);

      } catch (error) {
        console.error('Erreur lors de la récupération des commandes', error);
      }
    };

    fetchGroupCommande();
  }, [numeroCommandeGroup]);

  return (
    <>
     <Header />
     <div className="livraison_groupee_body">

     <div className="livraison_group_title">
        <div className="text-wrapper">Suivi de Livraison groupée</div>
        <div className="vector"><img className="vector_img" src={vector} alt="vector" /></div>
     </div>
     { commandeGroup ?
       commandeGroup.map((cmd, index_cmd) => (

        <div className='wrap_ordergroup' key={index_cmd}>
                <div className="livraison_group_info">
                  <div className="n-de-livraison-group">N° de livraison groupée</div>
                  <div className="n-de-livraison-group-btnstyle">{cmd.deliveryNumber}</div>
                </div>

                <div className='all_order_in_group'>
                  <div className='all_order_in_group_carrousel'>
                    {cmd.orders && cmd.orders.map((order, index) => (
                      <OrderDetail commande={order} key={index} className={`order_form carousel-item ${index === currentIndex ? 'active' : 'inactive'}`}/>
                      ))}</div>
                  <img className="nova_logo_img" src="nova_icone_blanc248.png" alt="Novazon logo" />
                </div>
                <div className='carrousel_btns'>
                <button className="carousel_btn_prev" onClick={goToPrevSlide}>
                <img
                        className="arrow-2_elm3"
                        alt="Arrow"
                        src={arrow33}
                        />
                </button>
                <button className="carousel_btn_next" onClick={goToNextSlide}>
                  <img
                        className="arrow-2_elm3"
                        alt="Arrow"
                        src={arrow33}
                        />
                  </button></div>
        </div>
        )) : (
          <Loading/>
        )
      }
       {
      commandeGroup && commandeGroup.length === 0  && !numeroCommandeGroup && <EmptyResultPage/> 

      }
      {commandeGroup && commandeGroup.length === 0  && numeroCommandeGroup &&
       <>
        <EmptyResultPage/>
        <div className='error_not_found_msg'>
          <strong className='warning_text'>
            Attention !!! &nbsp;&nbsp;
          </strong>
          Aucune commande groupée ne correspond au numéro " <strong className='num_bold'>{numeroCommandeGroup}</strong>" que vous avez saisi.
          <br/>
          Merci de saisir un numéro de commandes groupées valide.
        </div>
      </>
      }
    </div>
    <Footer />
    </>
  )
}

export default LivraisonGroup