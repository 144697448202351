import React from 'react';
import appStore from '../../assets/app_store.svg';
import googlePlay from '../../assets/google_play.svg';
import { Link } from 'react-router-dom';

function HomeBodySection3() {
  return (
      <div className='section3_home'>
      <div className="section3_home_group-7">
                  <p className="achetez-et-fa-tes_home">
                        Achetez et faîtes vous livrer avec
                        NOVAZON Relay sur
                        l&#39;application mobile
                  </p>
                  <p className="text-wrapper-11_home">
                  Nos vendeurs certifiés n’attendent que vous ! Découvrez tous les articles disponibles dès
                  maintenant. 
                  </p>
                  <div className='logos_home'>
                        <div className="logos-google-play-wrapper_home">
                              <Link to='https://play.google.com/store/apps/details?id=com.novazonhub.novazon' target="_blank">
                              <img
                                    className="logos-google-play_home"
                                    alt="Logos google play"
                                    src={googlePlay}
                              /></Link>
                        </div>
                        <div className="overlap-4_home">
                              <Link to="https://apps.apple.com/fr/app/novazon/id6474528769" target="_blank">
                              <div className="rectangle-5_home">
                                    <img
                                          className="logos-apple-app_home"
                                          alt="Logos apple app"
                                          src={appStore}
                                    />
                                    <div className="text-wrapper-12_home">
                                          App Store
                                    </div>
                              </div>
                              </Link>
                        </div>
                  </div>
      </div>
      <div className='section3_home_image_div'>
            <img
                  className="section3_home_image"
                  alt="Rectangle"
                  src="visuel2.png"
            />
      </div>
      {/* <img
            className="LOGO-NOVAZON-icone-2_home"
            alt="Logo NOVAZON icone"
            src="LOGONOVAZONiconeblanc2.png"
      /> */}
</div>
  )
}

export default HomeBodySection3