import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import vector from '../../assets/vector.svg';
import SuiviItem from '../../components/body/SuiviItem';
import './suivi.css';
import Footer from '../../components/footer/Footer';
import { useLocation } from 'react-router-dom';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Loading from '../../components/body/Loading';
import EmptyResultPage from '../../components/body/EmptyResultPage';

function SuiviColis() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const numeroCommande = queryParams.get('query');
  const [commandeDetails, setCommandeDetails] = useState(null);

  useEffect(() => {

    document.title = 'SUIVI de COLIS | NOVAZON RELAY';
    window.scrollTo(0, 0);

    const fetchCommandeDetails = async () => {
      try {

        const q = query(collection(db, 'Commandes'), where('commandRef', '==', numeroCommande));
        const querySnapshot = await getDocs(q);
  
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
  
        setCommandeDetails(data);

      } catch (error) {
        console.error('Erreur lors de la récupération des détails de la commande', error);
      }
    };

    fetchCommandeDetails();
  }, [numeroCommande]);

  return (
    <>
    <Header />
    <div className="suivi_colis_body">

    <div className="suivi_colis_title">
       <div className="text-wrapper">Suivi de Colis</div>
       <div className="vector"><img className="vector_img" src={vector} alt="vector" /></div>
    </div>

    { commandeDetails ?
       commandeDetails.map((cmd) => ( <div className='wrap_suivi_colis' key={cmd.id} >

        <div className="suivi_colis_info">
          <div className="n-de-suivi">N° de suivi de colis</div>
          <div className="n-de-suivi-btnstyle">{cmd.commandRef}</div>
            </div>
                  <div className='item_style_suivi'>
                    <SuiviItem commande={cmd}/>
                    <img className="nova_logo_img" src="nova_icone_blanc248.png" alt="Novazon logo" />
                  </div>
        </div>)) : (
        <Loading/>
      )
    }
    {
      commandeDetails && commandeDetails.length === 0  && !numeroCommande && <EmptyResultPage/>
    }
    {commandeDetails && commandeDetails.length === 0  && numeroCommande &&
       <>
        <EmptyResultPage/>
        <div className='error_not_found_msg'>
          <strong className='warning_text'>
            Attention !!! &nbsp;&nbsp;
          </strong>
          Aucune commande ne correspond au numéro "<strong className='num_bold'>{numeroCommande}</strong>" que vous avez saisi.
          <br/>
          Merci de saisir un numéro de commande valide.
        </div>
      </>
      }
   </div>
   <Footer />
   </>
  )
}

export default SuiviColis