import React, {useState, useEffect} from 'react';
import {db} from '../../utils/firebase';
import { useNavigate } from 'react-router-dom'; 
import { getDocs, collection, query, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';

function GroupCard() {

      const [numeroCommande, setNumeroCommande] = useState('');
      const [numeroCommandeGroup, setNumeroCommandeGroup] = useState('');
      const [quartier, setQuartier] = useState('');
      const [quartierList, setQuartierList] = useState([]);

      const [commandeExiste, setCommandeExiste] = useState(true);
      const [commandeGroupExiste, setCommandeGroupExiste] = useState(true);
      const [quartierExiste, setQuartierExiste] = useState(true);

      const navigate = useNavigate();

      useEffect(() => {
            const fetchQuartiers = async () => {
              try {
        
                const q = query(collection(db, 'Quartiers'), orderBy("nom", "asc"));
                const querySnapshot = await getDocs(q);
          
                const data = [];
                querySnapshot.forEach((doc) => {
                  data.push({ id: doc.id, ...doc.data() });
                });
          
                setQuartierList(data);
        
              } catch (error) {
                console.error('Erreur lors de la récupération de la liste des Quartiers', error);
              }
            };
        
            fetchQuartiers();
          }, []);

      const handleSubmit1 = async (e) => {
            e.preventDefault();
            if (numeroCommandeGroup) {
                  setCommandeGroupExiste(true);
                  navigate(`/livraison-group?query=${numeroCommandeGroup}`);
            } else {
                  setCommandeGroupExiste(false);
            }
      };

      const handleSubmit2 = async (e) => {
            e.preventDefault();
            if (numeroCommande) {
                  setCommandeExiste(true);
                  navigate(`/suivi-colis?query=${numeroCommande}`);
            } else {
                  setCommandeExiste(false);
            }
      };

      const handleSubmit3 = async (e) => {
            e.preventDefault();
            if (quartier) {
                  setQuartierExiste(true);
                  navigate(`/relais?query=${quartier}`);
            } else {
                  setQuartierExiste(false);
            }
      };

      return (
        <div className="group_section1_home">
            <div className="card_colis_section1_home">
                  <div className="overlap-group_section1">
                        <div className="overlap-group-wrapper_section1 d_none_mobile">
                              <div className="div-wrapper_section1">
                                    <div className="text-wrapper-3_section1">
                                          Suivre une
                                          livraison
                                          groupée
                                    </div>
                              </div>
                        </div>
                        
                        <div className='d_flex_mobile'>
                        <img className="card_search_img d_block_mobile" src="livraison_group.jpg" alt="livraison groupee ..."/>
                        <Link to="/livraison-group" className='btn_card_link_mobile btn_card_link_mobile_1'>Suivre</Link>
                        </div>

                        <p className="quelques-clics_section1">
                              <strong className='title_group_card'>Suivre une livraison groupée  <br/></strong>
                             
                              Quelques clics suffisent
                              pour préparer
                              <br />
                              votre envoi. Votre colis
                              est arrivé ? Nous <br />
                              vous en informons par SMS
                              ou par mail.
                        </p>
                        <div className="group-2_section1">
                              <form className="section1_search" onSubmit={handleSubmit1}>
                                    <label htmlFor="section1_search_input_id" className='label_section1_search'>N° de livraison</label>
                                    <input className="section1_search_input" id="section1_search_input_id"
                                    type="text"
                                    placeholder='ex: 547001'
                                    value={numeroCommandeGroup}
                                    onChange={(e) => setNumeroCommandeGroup(e.target.value)}
                                    />
                                    <input className="section1_submit_btn"
                                    type="submit" value="Suivre" />
                              </form>
                        </div>
                        {!commandeGroupExiste && <p className="quelques-clics_section1_error">
                              Veuillez saisir un numéro de groupe de commandes valide.
                        </p>}
                  </div>
            </div>
            <div className="card_colis_section1_home">
                  <div className="overlap-group_section1">
                        <div className="overlap-group-wrapper_section1 d_none_mobile">
                              <div className="div-wrapper_section1">
                                    <div className="text-wrapper-3_section1">
                                          Suivre un
                                          colis
                                    </div>
                              </div>
                        </div>

                        <div className='d_flex_mobile'>
                        <img className="card_search_img d_block_mobile" src="suivi_colis.jpeg" alt="suivi colis ..."/>
                        <Link to="/suivi-colis" className='btn_card_link_mobile btn_card_link_mobile_1'>Suivre</Link>
                        </div>

                        <p className="quelques-clics_section1">
                              <strong className='title_group_card'>Suivre un colis  <br/></strong>
                              Votre colis a de la valeur
                              pour nous. Nous
                              <br />
                              veillons dessus. Tout
                              comme nous, vous
                              <br />
                              pouvez le suivre à la
                              trace
                        </p>

                        <div className="group-2_section1">
                              <form className="section1_search" onSubmit={handleSubmit2}>
                                    <label htmlFor="section1_search_input_id" className='label_section1_search'>N° de suivi</label>
                                    <input className="section1_search_input" id="section1_search_input_id"
                                    type="text"
                                    placeholder='ex: 17699063'
                                    value={numeroCommande}
                                    onChange={(e) => setNumeroCommande(e.target.value)}
                                    />
                                    <input className="section1_submit_btn"
                                    type="submit" value="Suivre" />
                              </form>
                        </div>
                        {!commandeExiste && <p className="quelques-clics_section1_error">
                              Veuillez saisir un numéro de commande valide.
                        </p>}
                        
                  </div>
            </div>
            <div className="card_colis_section1_home">
                  <div className="overlap-group_section1">
                        <div className="overlap-group-wrapper_section1 d_none_mobile">
                              <div className="div-wrapper_section1">
                                    <div className="text-wrapper-3_section1">
                                          Trouver un
                                          point relais
                                    </div>
                              </div>
                        </div>

                        <div className='d_flex_mobile'>
                        <img className="card_search_img d_block_mobile" src="location_clipart.jpeg" alt="relais ..."/>
                        <Link to="/relais" className='btn_card_link_mobile'>Découvrir</Link>
                        </div> 

                        <p className="quelques-clics_section1">
                        <strong className='title_group_card'>Trouver un point relai <br/></strong>
                              Avec nos nombreux Relais®,<br />
                               il est facile de localiser celui qui se trouve <br />
                               le plus près de votre emplacement.
                        </p>

                        <div className="group-2_section1">
                              <form className="section1_search" onSubmit={handleSubmit3}>
                                    <label htmlFor="section1_search_input_id" className='label_section1_search'>Quartier</label>
                                    
                                    <select className="section1_search_input" id="section1_search_input_id"
                                    value={quartier}
                                    onChange={e => setQuartier(e.target.value)}
                                    >
                                    <option value="">ex: Adidogomé</option>
                                    {quartierList && quartierList.map((qrt) => (
                                          <option value={qrt.nom} key={qrt.id}>{qrt.nom}</option>
                                     ))}
                                    </select>

                                    <input className="section1_submit_btn"
                                    type="submit" value="Découvrir" />
                              </form>
                        </div>
                        {!quartierExiste && <p className="quelques-clics_section1_error">
                              Veuillez sélectionner un quartier.
                        </p>}
                  </div>
            </div>
       </div>
      );
}

export default GroupCard;