import React, {useState, useEffect} from 'react';

function SuiviItem(props) {

  const [statusRecueClass, setStatusRecueClass] = useState('');
  const [statusPrepClass, setStatusPrepClass] = useState('');
  const [statusExpClass, setStatusExpClass] = useState('');
  const [statusEntClass, setStatusEntClass] = useState('');
  const [statusLivRerelClass, setStatusLivreRelClass] = useState('');
  const [statusLivreClClass, setStatusLivreClClass] = useState('');

  const cmd_recue = 'CommandStatus.Commande_recue';
  const cmd_exp = 'CommandStatus.expedie_par_le_vendeur';
  const cmd_liv_r = 'CommandStatus.livre_en_relais';

  const cmd_liv_clt = 'CommandStatus.livre_au_client';
  const cmd_ent = 'CommandStatus.en_entrepot';
  const cmd_prepa = 'CommandStatus.En_cours_de_preparation';


  useEffect(() => {

    if(props.commande){
  
      if(props.commande.statue === cmd_recue){
        setStatusRecueClass('current');
        setStatusPrepClass('');
        setStatusExpClass('');
        setStatusEntClass('');
        setStatusLivreRelClass('');
        setStatusLivreClClass('');
      }
      else if(props.commande.statue === cmd_exp){
        setStatusRecueClass('is-done');
        setStatusPrepClass('is-done');
        setStatusExpClass('current');
        setStatusEntClass('');
        setStatusLivreRelClass('');
        setStatusLivreClClass('');
      }
      else if(props.commande.statue === cmd_liv_r){
        setStatusRecueClass('is-done');
        setStatusPrepClass('is-done');
        setStatusExpClass('is-done');
        setStatusEntClass('is-done');
        setStatusLivreRelClass('current');
        setStatusLivreClClass('');
      }
      else if(props.commande.statue === cmd_liv_clt){
        setStatusRecueClass('is-done');
        setStatusPrepClass('is-done');
        setStatusExpClass('is-done');
        setStatusEntClass('is-done');
        setStatusLivreRelClass('is-done');
        setStatusLivreClClass('is-done');
      }
      else if(props.commande.statue === cmd_ent){
          setStatusRecueClass('is-done');
          setStatusPrepClass('is-done');
          setStatusExpClass('is-done');
          setStatusEntClass('current');
          setStatusLivreRelClass('');
          setStatusLivreClClass('');
      }
      else if(props.commande.statue === cmd_prepa){
        setStatusRecueClass('is-done');
        setStatusPrepClass('current');
        setStatusExpClass('');
        setStatusEntClass('');
        setStatusLivreRelClass('');
        setStatusLivreClClass('');
      }
      else{
        console.log('Invalide Status');
      }
  
    }

  }, []);

 
  
  return (
    <div className='item_class_suivi_unit'>
      <ul className="StepProgress">
        <li className={`StepProgress-item ${statusRecueClass}`}><strong>Commande reçue</strong></li>
        <li className={`StepProgress-item ${statusPrepClass}`}><strong>En cours de préparation</strong></li>
        <li className={`StepProgress-item ${statusExpClass}`}><strong>Colis expédié par le vendeur</strong></li>
        <li className={`StepProgress-item ${statusEntClass}`}><strong>En entrepôt</strong></li>
        <li className={`StepProgress-item ${statusLivRerelClass}`}><strong>Colis livré en relais</strong></li>
        <li className={`StepProgress-item ${statusLivreClClass}`}><strong>Livré au client</strong></li>
      </ul>        
    </div>
  )
}

export default SuiviItem