import React from 'react';
import quoteAltLeft from '../../assets/quote_alt_left.svg';
import ellipse317 from '../../assets/ellipse-317.svg';

function HomeBodySection4() {
  return (
    <div className='section4_home'>

        <div className='section4_home_body1'>
            <div className="ce-qu-ils-disent-wrapper_home">
                <div className="ce-qu-ils-disent_home">
                        Ce qu&#39;ils disent
                </div>
            </div>
            <div className='bxs-quote-alt-left_home_wrap'>
            <img
                    className="bxs-quote-alt-left_home"
                    alt="Bxs quote alt left"
                    src={quoteAltLeft}
            /></div>
        </div>
        <div className='section4_home_body2'>
            <p className="j-adore-la-simplicit_home">
                “J&#39;adore la simplicité du service et la
                rapidité
                <br />
                service client. Nous ne pouvons pas imaginer
                <br />
                travailler sans ça.”
            </p>
        </div>
        <div className='section4_home_body3'>
                <div className='section4_home_body3_elm1'>
                        <img
                                className="ellipse-3_elm1"
                                alt="Ellipse"
                                src={ellipse317}
                        />
                </div>
                <div className='section4_home_body3_elm2'>
                        <div className="text-wrapper-13_elm2">Felicien A. AMAVI</div>
                        <div className="text-wrapper-14_elm2">Graphic Designer</div>
                </div>
                
        </div>
    
    </div>
  )
}

export default HomeBodySection4