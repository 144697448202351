import React, {useEffect} from 'react';
import './gallery.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';


function Gallery() {

    useEffect(() => {
        document.title = 'Gallerie | NOVAZON RELAY';
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
    <Header/>
    <div className='gallery_container'>
        <div class="gallery">
            <img src="visuel1.png" alt="..."/>
            <img src="visuel2.png" alt="..."/>
            <img src="visuel3.png" alt="..."/>
            <img src="visuel4.png" alt="..."/>
            <img src="visuel5.png" alt="..."/>
            <img src="visuel6.png" alt="..."/>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Gallery