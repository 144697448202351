import React, {useEffect} from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import HomeBody from '../../components/body/HomeBody';
import './section0.css';
import './section1.css';
import './section2.css';
import './section3.css';
import './section4.css';

function Home() {

      useEffect(() => {
            document.title = 'ACCEUIL | NOVAZON RELAY';
            window.scrollTo(0, 0);
          }, []);

      return (
            <>
                  <Header />
                  <HomeBody />
                  <Footer />
            </>
      );
}

export default Home;
