import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ErrorBody from '../../components/body/errorBody';
import './error.css';

function Error404() {
  return (<>
    <Header />
    <ErrorBody />
    <Footer />
    </>
    
  )
}

export default Error404