import React, {useState, } from 'react';
import './emptypage.css';
import { useNavigate, useLocation } from 'react-router-dom';

function EmptyResultPage() {

  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (search) {
          const path = location.pathname;
          if (path === '/livraison-group'){
            navigate(`/livraison-group?query=${search}`);
          }
          else if(path === '/suivi-colis'){
            navigate(`/suivi-colis?query=${search}`);
          }
    }
};

  return (
    <>
    <form className="cours__section__form" onSubmit={handleSubmit}>
							<div className="cours__section__search">					
								<input type="text" name="q" placeholder="ex: GENEXT-B3SRJZ"
                value={search}
                onChange={(e) => setSearch(e.target.value)}/>
								<button type="submit" name="btnsearch" className="cours__section__search__btn">
									Suivre
								</button>
							</div>
		</form>
    </>
  )
}

export default EmptyResultPage