import React from 'react';
import GroupCard from './GroupCard';

function HomeBodySection1() {
  return (
    <div className='section1_home'>
      <div className='section1_home_title'>
      Gérez vos colis avec NOVAZON Relay
      </div>
      <div className='section1_home_description'>
          <p className="section1_home_description_parag">
              Suivez facilement vos colis et vos livraisons groupée. <br />
              Trouvez le point relais le plus proche de
              chez vous en un clin d&#39;œil.
          </p>
      </div>
      <div className='section1_home_body'>
        <GroupCard />
      </div>
    </div>
  )
}

export default HomeBodySection1