import React from 'react';

function HomeBodySection2() {
  return (
    <div className='section2_home'>
        <div className='section2_home_title'>
            Pourquoi choisir Novazon Relay ?
        </div>
        <div className='section2_home_description'>
            Grâce à une relation de confiance absolue avec nos relais, nous assurons la livraison de vos
            colis en toute sécurité.
        </div>
        <div className='section2_home_body'>
            <div className='section_home_img_div'>
                <img
                              className="section_home_delivery-pana"
                              alt="Delivery pana"
                              src="delivery-pana@2x.png"
                        />
            </div>
            <div className='section2_home_body_elements'>

                <div className="section2_home_group">
                              <div className="section2_hom3_pouces-vers-le-haut-wrapper">
                                    <img
                                          className="section2_home_pouces-vers-le-haut"
                                          alt="Pouces vers le haut"
                                          src="pouces-vers-le-haut-1-2@2x.png"
                                    />
                              </div>
                              <div className='section2_home_group_content'>
                                <div className="section2_home_text-wrapper-9">Pratique</div>
                                    <p className="section2_home_text-wrapper-10">
                                            Une centaine de points Relais® pour
                                            <br />
                                            envoyer et retirer vos colis.
                                    </p>
                            </div>
                </div>
                <div className="section2_home_group">
                              <div className="section2_hom3_pouces-vers-le-haut-wrapper">
                                    <img
                                          className="section2_home_pouces-vers-le-haut"
                                          alt="Pouces vers le haut"
                                          src="pouces-vers-le-haut-1-1@2x.png"
                                    />
                              </div>
                              <div className='section2_home_group_content'>
                                    <div className="section2_home_text-wrapper-9">Economique</div>
                                    <p className="section2_home_text-wrapper-10">
                                    Des tarifs plus attractifs que la livraison à domicile.
                                    </p>
                              </div>
                </div>
                <div className="section2_home_group">
                              <div className="section2_hom3_pouces-vers-le-haut-wrapper">
                                    <img
                                          className="section2_home_pouces-vers-le-haut"
                                          alt="Pouces vers le haut"
                                          src="pouces-vers-le-haut-1@2x.png"
                                    />
                              </div>
                              <div className='section2_home_group_content'>
                                    <div className="section2_home_text-wrapper-9">Responsable</div>
                                    <p className="section2_home_text-wrapper-10">
                                    Un impact environnemental limité grâce à notre livraison en points 
                                        de proximité.
                                    </p>
                              </div>
                </div>


            </div>
        </div>
    </div>
  )
}

export default HomeBodySection2