import React from 'react';
import RelaiListElem from "./RelaiListElem";
import MapNova from './map';

function RelaisItem(props) {

  return (
    <div className='row item_class_relai_unit'>
      <div id="relais_list" className='justify-content-center col-md-6 relais_list'>
      {props.listRelais && props.listRelais.length !== 0 && props.listRelais.map((relais, index) => (
        <RelaiListElem relais={relais} key={index} index={relais.id_number}/>))}
      {
        props.listRelais && props.listRelais.length === 0 && props.oldlistRelais && props.oldlistRelais.map((relais, index) => (
          <RelaiListElem relais={relais} key={index} index={relais.id_number}/>))
      }
      </div>      
      <div id="relais_wrap_map" className='justify-content-center col-md-6 relais_wrap_map'>
        <div className='relais_map'>
            <MapNova listRelais={props.listRelais && props.listRelais.length !== 0 ? props.listRelais : props.oldlistRelais}
              userLocation={props.userLocation}/>
        </div>
      </div>      
    </div>
  )
}

export default RelaisItem