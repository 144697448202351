import React, {useEffect} from 'react';
import Header from '../../components/header/Header';
import ContactForm from '../../components/body/ContactForm';
import '../Contact/contact.css';
import Footer from '../../components/footer/Footer';


function Aide() {

  useEffect(() => {
    document.title = 'AIDE | NOVAZON RELAY';
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
                  <Header />
                  <ContactForm page={"aide"}/>
                  <Footer />
            </>
  )
}

export default Aide