import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './header.css';
import earth from '../../assets/gis_earth.svg';

function Header() {
      const [isOpen, setIsOpen] = useState(false);
      return (
            <header className="Navbar">
                  <div className="rectangle-1"></div>
                  <div className="nav-logo">
                        <div className="overlap-group1"><Link to="/">
                              <div className="novazon-container">
                                    <img
                                          className="logo-novazon-icone-copy-1"
                                          src="LOGO_NOVAZON.png"
                                          alt="LOGO NOVAZON"
                                    />
                                    <div className="novazon">NOVAZON</div>
                              </div>
                              <div className="relay">RELAY</div></Link>
                        </div>
                  </div>
                  <div className={`nav-items ${isOpen && 'open'}`}>
                        <NavLink to="/" className={({ isActive }) => isActive ? "active_nav" : "" }>Accueil</NavLink>
                        <NavLink to="/livraison-group" className={({ isActive }) => isActive ? "active_nav" : "" }>Livraison groupée</NavLink>
                        <NavLink to="/suivi-colis" className={({ isActive }) => isActive ? "active_nav" : "" }>Suivi de colis</NavLink>
                        <NavLink to="/relais" className={({ isActive }) => isActive ? "active_nav" : "" }>Relais</NavLink>
                        <NavLink to="/faqs" className={({ isActive }) => isActive ? "active_nav" : "" }>Aide</NavLink>
                        <Link to="/contact-us" type="button" className="btn_contact">
                              CONTACTER
                        </Link>
                  </div>

                  <div className="frame-1">
                        <div className="locate">
                              <img
                                    className="gisearth"
                                    src={earth}
                                    alt="gis:earth"
                              />
                              <div className="togo">(TOGO)</div>
                        </div>
                        <Link to="/contact-us" className="btn_contact">CONTACTER</Link>
                  </div>

                  <div
                        className={`nav-toggle ${isOpen && 'open'}`}
                        onClick={() => setIsOpen(!isOpen)}
                  >
                        <div className="bar"></div>
                  </div>
            </header>
      );
}

export default Header;
