import React, { useState, useEffect} from 'react';
import Header from '../../components/header/Header';
import vector from '../../assets/vector.svg';
import RelaisItem from '../../components/body/RelaisItem';
import './relais.css';
import Footer from '../../components/footer/Footer';
import { useLocation , useNavigate } from 'react-router-dom';
import { getDocs, collection, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Loading from '../../components/body/Loading';

function Relais() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quartierRelais = queryParams.get('query');
  const [oldquartierRelais, setOldquartierRelais] =  useState('');
  const [listRelais, setListRelais] = useState(null);
  const [oldlistRelais, setOldlistRelais] = useState(null);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [quartierList, setQuartierList] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

 
  const fetchCommandeDetails = async () => {
    try {
      const data = [];
      const data_all = [];
      var q = quartierRelais ? 
      query(collection(db, 'Relais'), where('quartier', '==', quartierRelais), where("confirm", "==", true)) : 
      query(collection(db, 'Relais'), where("confirm", "==", true));
     
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      if (data.length === 0 ){
        var q_all = query(collection(db, 'Relais'), where("confirm", "==", true));
     
        const querySnapshot_all = await getDocs(q_all);

          querySnapshot_all.forEach((doc) => {
            data_all.push({ id: doc.id, ...doc.data() });
        });
        setOldlistRelais(data_all);
      }
      
      setListRelais(data);

    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la commande', error);
    }
  };

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
        },
        (error) => {
          console.error('Error getting geolocation:', error.message);
        }
      );
    } else {
      alert("La Géolocalisation n'est pas supportée par votre navigateur");
    }
  };

  useEffect(() => {

    document.title = 'RELAIS | NOVAZON RELAY';
    window.scrollTo(0, 0);

    const fetchQuartiers = async () => {
      try {
        // 
        const qrt = query(collection(db, 'Quartiers'), orderBy("nom", "asc"));
        const querySnapshotqrt = await getDocs(qrt);
          
        const dataqrt = [];
        querySnapshotqrt.forEach((doc) => {
          dataqrt.push({ id: doc.id, ...doc.data() });
        });
          
        setQuartierList(dataqrt);
        // 

      } catch (error) {
        console.error('Erreur lors de la récupération de la liste des Quartiers', error);
      }
    };

    fetchCommandeDetails();
    fetchQuartiers();
    handleGeolocation();
  }, []);

  if(quartierRelais !== oldquartierRelais){
    fetchCommandeDetails();
    setOldquartierRelais(quartierRelais);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (search) {
        
        navigate(`/relais?query=${search}`);
          
    }

};

function toggleListMap(id) {
  
  const btn_list = document.getElementById('btn_list');
  const btn_map = document.getElementById('btn_map');

  const map_div = document.getElementById('relais_wrap_map');
  const list_div = document.getElementById('relais_list');

  if(id === 'btn_list'){
    btn_list.style.display = "none";
    list_div.style.display = "block";
    btn_map.style.display = "block";
    map_div.style.display = "none";
  }
  else if(id === 'btn_map'){
    btn_list.style.display = "block";
    list_div.style.display = "none";
    btn_map.style.display = "none";
    map_div.style.display = "block";
  }
}




  return (
    <>
    <Header />
    <div className="relais_body">

    <div className="relais_title">
       <div className="text-wrapper">Points Relais</div>
       <div className="vector"><img className="vector_img" src={vector} alt="vector" /></div>
    </div>

     <div className='wrap_relais_colis'>

     <div className="relais_colis_info">
    
       <form className="cours__section__form_relais" onSubmit={handleSubmit}>
							<div className="cours__section__search">					
								
                <select className="section1_search_input_relais"
                  value={search}
                  onChange={e => setSearch(e.target.value)}>
                    <option value="">ex: Adidogomé</option>
                      {quartierList && quartierList.map((qrt) => (
                      <option value={qrt.nom} key={qrt.id}>{qrt.nom}</option>))}
                </select>
								<button type="submit" name="btnsearch" className="cours__section__search__btn cours__section__search__btn_custom">
									Rechercher
								</button>
							</div>
		  </form>
     </div>
     {
      listRelais && listRelais.length === 0 && 
      <div className='error_not_found_msg'>
         &nbsp; Nous arrivons bientôt dans votre quartier " <strong className='num_bold'>{quartierRelais}</strong>". &nbsp; 
          <br/>
          &nbsp; Aucun relais disponible actuellement dans votre quartier.  &nbsp; 
         <br/>
         &nbsp;  Merci de rechercher le relais le plus proche de vous dans l'ensemble de nos relais disponibles.  &nbsp; 
      </div> 
    }
     <div className="switch_btn_mobile">

        <button id="btn_list" className="switch_btn btn_list" onClick={() => toggleListMap("btn_list")}>
        Vue liste
				</button>
        <button id="btn_map" className="switch_btn btn_map" onClick={() => toggleListMap("btn_map")}>
        Vue carte
				</button>
     </div>
     <div className='geolocate_user'>
      <button className="geolocate_me" onClick={handleGeolocation}>Ma position</button>
      </div>
         <div className='item_style_relais'>
         { listRelais ?
           (<RelaisItem listRelais={listRelais} oldlistRelais={oldlistRelais} userLocation={userLocation}/>) : (
            <Loading/>
          )}

         </div>
     </div>
   </div>
   <Footer />
   </>
  )
}

export default Relais